@font-face {
    font-family: 'VeluxGothic RegItalic';
    src: url('../fonts/VeluxGothic-RegItalic.woff2') format('woff2'),
        url('../fonts/VeluxGothic-RegItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'VeluxGothicCYR';
    src: url('../fonts/VeluxGothicCYR-Bold.woff2') format('woff2'),
        url('../fonts/VeluxGothicCYR-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-BoldItalic.woff2') format('woff2'),
        url('../fonts/VeluxGothic-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'VeluxGothicCYR';
    src: url('../fonts/VeluxGothicCYR-Regular.woff2') format('woff2'),
        url('../fonts/VeluxGothicCYR-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-BlackItalic.woff2') format('woff2'),
        url('../fonts/VeluxGothic-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'VeluxGothicCYR';
    src: url('../fonts/VeluxGothicCYR-Black.woff2') format('woff2'),
        url('../fonts/VeluxGothicCYR-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Regular.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Light.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-LightItalic.woff2') format('woff2'),
        url('../fonts/VeluxGothic-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Black.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Bold.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-BoldItalic.woff2') format('woff2'),
        url('../fonts/VeluxGothic-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Light.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Bold.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic RegItalic';
    src: url('../fonts/VeluxGothic-RegItalic.woff2') format('woff2'),
        url('../fonts/VeluxGothic-RegItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Black.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-Regular.woff2') format('woff2'),
        url('../fonts/VeluxGothic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VeluxGothic';
    src: url('../fonts/VeluxGothic-LightItalic.woff2') format('woff2'),
        url('../fonts/VeluxGothic-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

