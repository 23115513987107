:root {
    --kof: calc(100vw / 1920);
    --roomHeightKof: calc(100vh/1080);

    @media screen and (max-width: 640px ){
        --kof-mobile: calc(100vw / 640);
    }
}


$red: #ff0000;
$black: #333333;
$grey: #666666;