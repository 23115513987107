html[style*=''],
body {
    font-family: VeluxGothic, sans-serif;
}

@media screen and (min-width: 1024px){
    body {
        overflow: hidden !important;
    }
}

.svg-line{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.svg-line path{
    fill: transparent;
}

.container {
    width: calc(var(--kof) * 1440);
    margin: 0 auto;
}

.section {
    position: relative;
    //padding-top: 60px;

    &-container {
        position: relative;
        //z-index: 20;
        height: 100%;
        display: flex;
        align-items: center;

        @media screen and (min-width: 1100px) {
            padding-top: calc(var(--kof) * 30);
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &__img {
            width: 100%;
            object-fit: cover;
            height: 100%;

        }
    }

    &__header {
        position: absolute;
        top: 60px;
        top: calc(var(--roomHeightKof)*60);

        @media screen and (max-width: 991px){
            top: calc(var(--roomHeightKof)*50);
        }
    }

    &-content{
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__reverse{
            flex-direction: row-reverse;
        }
    }

    &-title{
        color: $black;
        font-size: calc(var(--kof) * 40);
        font-weight: 700;
        line-height: 1.125;
        text-align: left;
        margin-bottom: .9em;

        @media screen and (max-width: 992px){
            font-size: calc(var(--kof) * 44);
        }

        @media screen and (max-width: 640px){
            font-size: calc(var(--kof) * 64);
        }
    }

    &-text{


        p, ul{
            color: $grey;
            font-size: calc(var(--kof) * 19);
            font-weight: 300;
            line-height: 1.3157;
            text-align: left;
            margin-bottom: 1.2em;
        }

    }
    
    &__blue{
        background: {
            image: url(../img/blue-shape.png);
            position: calc(var(--kof) * 83) center;
            size: calc(var(--kof) * 750);
            repeat: no-repeat;
        };
    }
    &__green{
        background: {
            image: url(../img/green-shape.png);
            position: calc(var(--kof) * 600) center;
            size: calc(var(--kof) * 750);
            repeat: no-repeat;
        };
    }
    &__brown{
        background: {
            image: url(../img/brown-shape.png);
            position: calc(var(--kof) * 83) center;
            size: calc(var(--kof) * 750);
            repeat: no-repeat;
        };
    }

    @media screen and (max-width: 991px){

        min-height: 100vh;
        padding-top: 80px;
        padding-top: calc(var(--roomHeightKof)*150);
        padding-bottom: 40px;

        &-container{
            position: static;
            //min-height: 100vh;
        }

        &__brown,
        &__blue,
        &__green{
            background-image: none;
            .vl-col-img{
                padding: 30px 0;
                background: {
                    position: center ;
                    size: calc(var(--kof) * 1260);
                    repeat: no-repeat;
                };
            }
        }

        &__brown{
            .vl-col-img {
                background-image: url(../img/brown-shape.png);
            }
        }
        &__blue {
            .vl-col-img {
                background-image: url(../img/blue-shape.png);
            }
        }
        &__green {
            .vl-col-img {
                background-image: url(../img/green-shape.png);
            }
        }



        &-content{
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &-text {
            p, ul {
                font-size: calc(var(--kof) * 36);
            }
        }
    }

    @media screen and (max-width: 640px){

        padding-bottom: calc(var(--roomHeightKof)*100);


        &-container{
            align-items: flex-start;
        }


        &-text {
            p, ul {
                font-size: calc(var(--kof) * 50);
            }

            li{
                margin-bottom: .5em;
                padding-left: 25px;

                &:before{
                    top: 0;
                }
            }
        }
    }

    @media screen and (min-width: 992px) and (min-height: 1070px){
        &__brown,
        &__blue,
        &__green{
            background-size: calc(var(--kof) * 800);
        }
    }

    @media screen and (min-width: 1100px) {
        &__brown,
        &__blue{
            background-position: calc(var(--kof) * 83) calc(50% + var(--kof) * 30);
        }

        &__green{
            background-position: calc(var(--kof) * 560) calc(50% + var(--kof) * 30);
        }

        &-content{
            padding-top: calc(var(--kof) * 30);
        }
    }

}

.section-item{
    .hero-title{
        padding-right: calc(var(--kof) * 60);
    }
}

.fp-tableCell {
    display: block;
}

.logo{
    height: calc(var(--kof) * 82);
    width: calc(var(--kof) * 180);
    display: block;

    @media screen and (max-width: 991px){
        height: calc(var(--kof) * 120);
        width: calc(var(--kof) * 360);
    }

    &__text{
        font-size: calc(var(--kof) * 19);
        font-weight: bold;
        color: #fff;
        display: block;
        text-align: center;
        &_black{
            @extend .logo__text;
            color: #333333;
        }

        @media screen and (max-width: 991px){
            font-size: calc(var(--kof) * 30);
        }

        @media screen and (max-width: 640px){
            font-size: calc(var(--kof) * 36);
        }

    }
}

.hero {
    color: #fff;
    padding-top: 0;

    &-header {
        align-self: flex-start;
    }
    &-container {
        width: 100%;
        height: 100%;
        padding: 60px calc(var(--kof) * 160) 0 calc(var(--kof) * 240);
        position: relative;
        z-index: 20;
        display: flex;
        justify-content: space-between;
    }
    &-content {
        width: calc(var(--kof) * 800);
        align-self: center;

        @media screen and (max-width: 992px){
            width: 80%;
        }
    }

    &-title {
        font-weight: bold;
        font-size: calc(var(--kof) * 70);
        line-height: 1.1428;
        margin-bottom: .7428em;

        @media screen and (max-width: 992px){
            font-size: calc(var(--kof) * 90);
        }

        @media screen and (max-width: 640px){
            font-size: calc(var(--kof) * 110);
        }
    }
    &-text {
        font-size: calc(var(--kof) * 24);
        //font-weight: bold;
        line-height: 1.4583;
        padding-right: calc(var(--kof) * 80);

        p{
            margin-bottom: 1.208em;
        }

        li{
            padding-left: 41px;
            position: relative;

            &:before{
                display: inline-block;
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #ff0000;
                left: 0;
                top: 8px;

                @media screen and (max-width: 640px){
                    top: 4px;
                }
            }
        }

        @media screen and (max-width: 992px){
            font-size: calc(var(--kof) * 44);
        }

        @media screen and (max-width: 640px){
            font-size: calc(var(--kof) * 64);
        }
    }

    @media screen and (max-width: 991px) {

        &-container {
            min-height: 100vh;
        }
    }
}


.section-text{
    @extend .hero-text;
    font-size: calc(var(--kof) * 19);
    color: $grey;
    ul{
        margin-bottom: 10px;
    }

    li:before{
        height: .75em;
        width: .75em;
        top: .3em;
    }


    @media screen and (max-width:640px){
        li{
            margin-bottom: .5em;
            padding-left: 25px;

            &:before{
                top: 0;
            }
        }
    }
}


.vl-col{
    &-img{
        width: 61.11%;
        display: flex;
        align-items: center;

        &__right{
            display: flex;
            justify-content: flex-end;
        }

    }
    &-content{
        width: 33.33%;
    }

    @media screen and (max-width: 991px){
        &-img{
            width: 100%;
            margin-bottom: 40px;
        }
        &-content{
            width: 60%;
            margin-right: auto;
        }
    }

    @media screen and (max-width: 640px){
        &-content{
            width: 90%;
            margin-right: auto;
        }

        &-img img{
            max-height: calc(var(--kof-mobile) * 300);
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }

    @media screen and (min-width: 992px){
        &-content {
            padding-top: calc(var(--kof) * 30);
        }

        &-img img{
            max-height: calc(var(--kof) * 530);
            width: 100%;
            object-fit: cover;
        }
    }
}


.btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: $red;
    background-image: url(../img/arrow.png);
    background-size: auto;
    background-position: calc(100% - 18px) center;
    background-repeat: no-repeat;
    color: #fff;
    padding: 0 50px 0 18px;

    -webkit-transition: background .3s ;
    -moz-transition: background .3s ;
    -ms-transition: background .3s ;
    -o-transition: background .3s ;
    transition: background .3s ;

    &:hover, &:focus{
        background-color: darken($red, 10%);
        background-position: calc(100% - 11px) center;
    }
}

.parallax-b{
    height: calc(var(--kof) * 540);
    position: relative;
}

.vl-badge{
    display: inline-block;
    background-color: $red;
    color: #fff;
    //font-weight: bold;
    line-height: 1;
    font-size: calc(var(--kof) * 30);
    padding: .33em .6em;
    margin-bottom: calc(var(--kof) * 30);

    @media screen and (max-width: 640px){
        font-size: calc(var(--kof) * 80);
        margin-bottom: 1em;
    }
}

.scroll-btn{
    background: {
        color: transparent;
        image: url(../img/arrow-scroll.png);
        repeat: no-repeat;
        position: center;
        size: contain;
    };
    border: none;

    position: absolute;
    bottom: calc(var(--kof) * 43);
    left: calc(50% - var(--kof) * 25);
    transform: translateY(0);
    height: calc(var(--kof) * 50);
    width: calc(var(--kof) * 50);
    z-index: 999;
    transition: .4s;

    &:hover, &:focus{
        opacity: .85;
        transform: translateY(13px);
    }

    @media screen and (max-width: 640px){
        width: calc(var(--kof-mobile) * 40);
        height: calc(var(--kof-mobile) * 40);
        left: calc(50% - var(--kof-mobile) * 20);
        bottom: calc(var(--kof-mobile) * 30);
    }
}

.vl-picture {
    width: 100%;

    position: relative;
    z-index: 2;
}


//for video

.vl-video-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-bottom: 56.25%;
    height: 0;
    z-index: 2;
}

.vl-video-wrapper iframe {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.vl-video-figure {
    position: absolute;
    height: auto;
    width: 100%;
    display: none;
}

.vl-video-wrapper .vl-video-thumbnail{
    max-height: 100%;

}