.footer {
    color: #999999;

    &-menu {

        display: flex;

        &__item {
            text-align: left;
            display: inline-block;
            margin-left: calc(var(--kof) * 22);

            a {
                color: #999999;
                font-size: calc(var(--roomHeightKof) * 14);
                font-weight: 300;
                transition: color .3s;

                &:hover,
                &:focus {
                    color: #fff;
                }
            }
        }
    }

    &-row {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-height: 760px) and (max-height: 770px) and (min-width: 991px) and (max-width: 1025px){
        .fp-tableCell{
            display: flex;
            flex-direction: column;
        }

        .footer-info{
            flex-grow: 1;
        }
    }

    @media screen and (max-width: 991px){

        padding-bottom: 0;

        &-row {
            flex-direction: column-reverse;
        }

        &-menu{
            &__item a{
                font-size: calc(var(--roomHeightKof) * 18);
            }
        }
    }
    @media screen and (max-width: 640px){
        padding: 0;
    }

}

.footer-another-link {
    padding: calc(var(--roomHeightKof) * 290) 0 calc(var(--roomHeightKof) * 150);

    @media screen and (max-width: 991px){
        padding: calc(var(--roomHeightKof) * 165) 0 calc(var(--roomHeightKof) * 30);
    }

}

.another-link {
    &-list {
        display: flex;
    }

    &-item {
        position: relative;
        width: calc(var(--kof) * 300);
        padding-left: calc(var(--kof) * 60);
        display: flex;
        align-items: center;
        height: calc(var(--kof) * 46);
        transition: border-bottom-color .3s;

        & + & {
            margin-left: calc(var(--kof) * 80);
        }

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: calc(var(--kof) * 40);
            width: calc(var(--kof) * 40);
            background-color: #a9cae5;
            background-image: url(../img/arrow.png);
            background-size: auto;
            background-repeat: no-repeat;
            background-position: center;
            transition: background-color .3s;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            width: 100%;
            background-color: #a9cae5;
        }

        &__text {
            color: #333333;
            font-size: calc(var(--kof) * 17);
            font-weight: 300;
            text-align: left;
        }

        &:hover, &:focus {
            border-bottom-color: #e50707;

            &:before, &:after {
                background-color: #e50707;
            }
        }
    }

    @media screen and (max-width: 991px){

        &-list{
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item{
            width: 40%;
            padding-left: calc(var(--kof) * 120);
            height: calc(var(--kof) * 90);
            margin-bottom: 20px;

            &__text{
                font-size: calc(var(--kof) * 34);
            }

            & + & {
                margin-left: 0;
            }

            &:before{
                height: calc(var(--kof) * 80);
                width: calc(var(--kof) * 80);
            }
        }
    }

    @media screen and (max-width: 640px){

        &-item{
            width: 48%;

            &__text{
                font-size: calc(var(--kof-mobile) * 16);
            }

            &:before{
                height: calc(var(--kof-mobile) * 30);
                width: calc(var(--kof-mobile) * 30);
                background-size: 50%;
            }

            &:after{
                bottom: -2px;
            }
        }

    }
}

.footer-contacts {
    background-color: #f6f6f6;
    padding: calc(var(--roomHeightKof) * 90) 0 calc(var(--roomHeightKof) * 95);

    &__title {
        color: #666666;
        font-size: calc(var(--kof) * 40);
        max-width: calc(var(--kof) * 400);
        font-weight: 700;
        line-height: 1.25;
        text-align: left;
        margin-bottom: calc(var(--kof) * 35);
    }

    &-info {
        &__title {
            color: #333333;
            font-size: calc(var(--kof) * 18);
            font-weight: 700;
            text-align: left;
            margin-bottom: .8889em;
        }

        &__text {
            color: #999999;
            font-size: calc(var(--kof) * 17);
            font-weight: 300;
            line-height: 1.4705;
            text-align: left;

            @media screen and (max-width: 991px){
                font-size: calc(var(--kof) * 35);
            }

            @media screen and (max-width: 640px){
                font-size: calc(var(--kof-mobile) * 18);
            }
        }

        @media screen and (max-width: 991px){
            &__title{
                font-size: calc(var(--kof) * 60);
            }
        }
    }

    @media screen and (max-width: 991px){

        padding: calc(var(--roomHeightKof) * 65) 0 calc(var(--roomHeightKof) * 75);

        .footer-row{
            flex-direction: column;
        }

        &__title{
            width: 100%;
            max-width: 100%;
            font-size: calc(var(--kof) * 80);
        }
    }

    @media screen and (max-width: 640px){
        padding: calc(var(--roomHeightKof) * 55) 0 calc(var(--roomHeightKof) * 65);
    }

}

.social {

    &-list {
        display: flex;
        margin-bottom: calc(var(--kof) * 35);

        @media screen and (max-width: 991px){
            margin-bottom: calc(var(--kof) * 70);
        }
    }
    &-item {
        width: calc(var(--kof) * 51);
        height: calc(var(--kof) * 50);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        & + & {
            margin-left: calc(var(--kof) * 24);
        }

        @media screen and (max-width: 991px){
            width: calc(var(--kof) * 81);
            height: calc(var(--kof) * 80);
        }

        @media screen and (max-width: 640px){
                width: calc(var(--kof-mobile) * 41);
                height: calc(var(--kof-mobile) * 40);
        }
    }

    &__link {
        display: block;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
        transition: opacity .3s;

        &_fb {
            background-image: url(../img/fb.png);
        }
        &_tw {
            background-image: url(../img/tw.png);
        }
        &_pn {
            background-image: url(../img/pn.png);
        }
        &_mail {
            background-image: url(../img/mail.png);
        }

        &:hover, &:focus {
            opacity: .75;
        }
    }
}

.subscribe-form {
    width: calc(var(--kof) * 560);
    max-width: 100%;

    &-row {
        display: -ms-flexbox;
        display: flex;
        border-radius: 5px;
        margin: 0 auto calc(var(--kof) * 20);
        transition: box-shadow .3s;
    }

    &__input {
        background-color: white !important;
        width: calc(var(--kof) * 400);
        height: 50px;
        padding: 0 16px;
        border-width: 1px;
        border-color: #d4d4d4;
        border-style: solid;
        margin-right: 10px;
        cursor: text;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #999999;
            font-size: 17px;
            font-weight: 300;
        }

        @media screen and (max-width: 1200px){
            height: calc(var(--roomHeightKof) * 50);
            font-size: 14px;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #999999;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }

    &__btn {
        background-image: none;
        background-color: #e50707;;
        width: calc(var(--kof) * 140);
        padding: 0;
        border: none;
        height: 50px;

        @media screen and (max-width: 1440px){
            width: calc(var(--kof) * 190);
            font-size: 14px;
        }
        @media screen and (max-width: 1200px){
            width: calc(var(--kof) * 220);
            height: calc(var(--roomHeightKof) * 50);
            font-size: 14px;
        }
    }

    @media screen and (max-width: 1200px){
        width: calc(var(--kof) * 640);
    }

    @media screen and (max-width: 991px){
        width: 100%;

        &-row{
            margin-bottom: 30px;
        }

        &-control{
            width: 75%;
        }

        &__input{
            width: 100%;
        }

        &__btn{
            width: 25%;
        }
    }

    @media screen and (max-width: 640px){
        &-control{
            width: 70%;
        }
        &__btn{
            width: 30%;
            font-size: calc(var(--kof-mobile) * 20);
        }
        &__input{
            -webkit-appearance: none;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }
    }
}

.invalid-feedback {
    display: none;
    font-size: calc(var(--kof) * 11);
    text-align: left;
    color: $red;
    padding: 2px 0;
}

.logo_footer {
    display: block;
    width: calc(var(--kof) * 90);
    height: auto;
    margin-bottom: calc(var(--kof) * 24);

    @media screen and (max-width: 991px){
        width: 150px;
        height: auto;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 640px){
        width: calc(var(--kof-mobile) * 150);
        height: auto;
    }
}

.copyright {
    //margin-right: 22px;

    @media screen and (max-width: 991px){
        width: 100%;
        text-align: center;
        margin: 20px 0;
    }
}

.footer-info {
    background-color: #2b2e2f;
    padding: calc(var(--roomHeightKof) * 47) 0 calc(var(--roomHeightKof) * 64);
    position: relative;
    z-index: 2;

    &__slogan {
        margin-left: auto;
        width: calc(var(--roomHeightKof) * 400);
    }

    &-col {
        width: 50%;
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    &-block {
        display: flex;
        font-size: calc(var(--roomHeightKof) * 14);
        font-weight: 300;
        text-align: left;
    }

    @media screen and (max-width: 991px){
        &-col{
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        &__slogan{
            margin: 0 auto 20px;
        }

        &-block{
            flex-direction: column-reverse;
            flex-wrap: wrap;
            align-items: center;

        }

    }
}
